/**
 * Prototype
 */

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { Image } from "@components";
import { graphql } from 'gatsby'

const Prototype = ({ 
    width,
    height,
    url,
    title,
    placeholder
    // type,
  }) => {
    const [hideIframe, setHideIframe] = useState(true);

    if (!url || !height || !width) {
      return null;
    }

    /**
    * Temporary fix for XD prototypes that automatically
    * force scrolls down to the prototype
    */
    useEffect(() => {
      const iframe = document.querySelector(".prototype iframe");

      if (iframe && window !== 'undefined') {
        iframe.onload= () => {
          setTimeout(() => {
            setHideIframe(false);
          }, 500);
        }
      }
    })

    const ratio = height / width;
    const paddingTop = `${ratio * 100}%`;
    const iframeStyle = hideIframe ? { display: 'none' } : {}
    const image = get(placeholder, 'localFile.childImageSharp.fluid', null);


    return (
      <div className={classnames("page__block page__block--prototype page__block--prototype--xd", { 'is-loading': hideIframe })}>

        <div className="prototype" style={{ paddingTop }}>
          {image && (
            <Image
              className="placeholder"
              alt={title}
              fluid={image}
            />
          )}
          <iframe
            scrolling="no"
            style={iframeStyle}
            title={title}
            src={url}
            allowFullScreen="allowfullscreen"
          />
        </div>
      </div>
  );
}

export default Prototype;

export const pageBuilderPrototypeQuery = graphql`
  fragment PageBuilderPrototype on WordPressAcf_prototype {
    ... on WordPressAcf_prototype {
      width
      height
      url
      title
      type
      placeholder {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`